import React, { useRef, useEffect, useState, useCallback } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PropTypes from "prop-types"
import Slider from "react-slick"
const About = () => {
  const AboutNextArrow = ({ className, onClick }) => (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <svg fill="none" viewBox="0 0 26 20">
        <path
          fill="#fff"
          d="M2 11.225a1.225 1.225 0 010-2.45v2.45zm23.052-2.091a1.225 1.225 0 010 1.732l-7.795 7.795a1.225 1.225 0 11-1.732-1.732L22.455 10l-6.93-6.929a1.225 1.225 0 011.732-1.732l7.795 7.795zM2 8.775h22.186v2.45H2v-2.45z"
        />
      </svg>
    </div>
  )

  AboutNextArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  const AboutPrevArrow = ({ className, onClick }) => (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <svg fill="none" viewBox="0 0 26 20">
        <path
          fill="#fff"
          d="M24.186 11.225a1.225 1.225 0 100-2.45v2.45zM1.134 9.134a1.225 1.225 0 000 1.732l7.795 7.795a1.225 1.225 0 101.732-1.732L3.732 10l6.93-6.929a1.225 1.225 0 10-1.733-1.732L1.134 9.134zm23.052-.359H2v2.45h22.186v-2.45z"
        />
      </svg>
    </div>
  )

  AboutPrevArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  }

  const settingsAbout = {
    infinite: true,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <AboutNextArrow />,
    prevArrow: <AboutPrevArrow />,
  }

  const animTeamStarted = useRef()
  const [animTeam, setAnimTeam] = useState(0)

  const updateAnimTeam = useCallback(() => {
    setAnimTeam(state => (state === 2 ? 0 : state + 1))
  }, [])

  const updateAnimhover = useCallback(() => {
    let interval

    if (!animTeamStarted.current) {
      interval = setInterval(() => updateAnimTeam(), 600)
      animTeamStarted.current = interval
    }
    return () => (interval ? clearInterval(interval) : null)
  }, [updateAnimTeam])

  const updateAnimLeave = useCallback(() => {
    if (animTeamStarted.current) {
      clearInterval(animTeamStarted.current)

      animTeamStarted.current = null

      setAnimTeam(0)
    }
  }, [])

  const heroAnim = useRef()
  const aboutAnim = useRef()

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (window.lottie) {
      window.lottie.loadAnimation({
        container: heroAnim.current,
        path: "/lottie/hero.json",
        renderer: "svg",
      })
      window.lottie.loadAnimation({
        container: aboutAnim.current,
        path: "/lottie/about.json",
        renderer: "svg",
      })
    }
  }, [])

  useEffect(() => {
    Array.from(document.querySelectorAll(".wrap-text")).forEach(el => {
      el.innerHTML = el.textContent.replace(/\S/g, "<span>$&</span>")
    })
  }, [])

  const [isTeamPopActive, setIsTeamPopActive] = useState(false)

  const setTeamPopActive = useCallback(value => e => {
    setIsTeamPopActive(state => (state === value ? null : value))
  })

  const setTeamPopClose = useCallback(() => {
    setIsTeamPopActive(false)
  }, [])

  return (
    <Layout>
      <Seo title="About" />

      <section className="hero-main about-hero">
        <div className="hero-anim" ref={heroAnim} />
        <div className="container">
          <div className="offset-2">
            <div className={`content ${loading ? "" : "visible"}`}>
              <h1>
                <span className="wrap-text">
                  <span>Do</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>more.</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>Grow</span>
                </span>
                <span>&nbsp;</span>
                <span className="wrap-text">
                  <span>more.</span>
                </span>
              </h1>
              <p>
                The true secret of our success lies in people behind the screen
              </p>
              <Link
                to="/contact/"
                className="button-arrow"
                style={{ color: "#4541DC" }}
              >
                Lets Connect{" "}
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                  <path
                    fill="#4541DC"
                    d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className="hero-bottom-content">
          <div className="container">
            <div className="flex-row">
              <div className="col col-6">
                <div className="scroll-down">
                  <p>Scroll</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#fff", padding: "10px 0" }}>
        <div className="container">
          <div className="about-slider" data-watch-visibility>
            <Slider {...settingsAbout}>
              <div>
                <img src="/img/office-1.jpg" alt="" />
              </div>
              <div>
                <img src="/img/office-2.jpg" alt="" />
              </div>
              <div>
                <img src="/img/office-3.jpg" alt="" />
              </div>
            </Slider>
          </div>
        </div>

        <div className="about-potato" data-watch-visibility>
          <div className="container">
            <div className="flex-row">
              <div className="col col-4">
                <h2 className="orange">Potato? really!</h2>
              </div>

              <div className="col col-7 offset-1">
                <p>
                  Our name comes from a simple philosophy. Nothing matches the
                  versatility of a potato. A culinary star on its own, and
                  subtle enhancement to when you need it - there’s so much to
                  love in every single potato!
                </p>
                <p>
                  Quite like that, we’re a bunch of talented, resourceful and
                  rooted designers that can pretty much solve anything you throw
                  at us! Experience design is second nature to us - but let our
                  creativity flow, and we promise there’s no design challenge we
                  won’t fulfill!
                </p>
                <p>
                  {" "}
                  Besides, all of us have been called potatoes at least once. So
                  we decided to own it.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {[
            {
              id: 0,
              title: "Who we are",
              description:
                "A team of ardent designers, tinkering with tasks, pushing boundaries and fostering dynamic change for the new-age.",
              number: "01",
            },
            {
              id: 1,
              title: "What we create",
              description:
                "Unmatched user experiences, outstanding interaction design, and branding that clicks, and buttery motion graphics.",
              number: "02",
            },
            {
              id: 2,
              title: "Our culture",
              description:
                "We nurture passion to create clean, accessible design and believe in harbouring a relationship of transparency and trust with our clients.",
              number: "03",
            },
            {
              id: 3,
              title: "Why us!",
              description: (
                <>
                  Simply because{" "}
                  <span style={{ color: "#4541DC", fontWeight: "500" }}>
                    WE CAN !
                  </span>{" "}
                  <br />
                  We’re thought starters who believe the best design is born out
                  of passion, and we work to bring it to every project, every
                  day!
                </>
              ),
              number: "04",
            },
          ].map(({ id, title, description, number }) => (
            <div className="why-potato" key={id} data-watch-visibility>
              <div className="flex-row">
                <div className="col col-9">
                  <h2 className="orange">{title}</h2>
                  <p>{description}</p>
                </div>
                <div className="col col-3">
                  <h4>{number}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="team-main">
        <div ref={aboutAnim} className="about-anim" />
        <div className="container">
          <div className="offset-2" data-watch-visibility>
            <h2>
              Hey, we just met you
              <br />
              and this is crazy!
            </h2>
          </div>

          <div className="flex-row">
            <div className="col col-5" data-watch-visibility>
              <div className="team-block-head">
                <h3 className="orange-heading">the squad</h3>
                <p>Here are the people who make the magic happen!</p>
              </div>
            </div>
            <div className="col col-3" data-watch-visibility>
              <div className="team-single">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(0)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/amruta.png",
                    },
                    {
                      id: 1,
                      img: "/img/amruta-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/amruta-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="amruta"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Amruta</h4>
                    <p>Head Potato</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/amruta-ap-56aa7933"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(1)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/harsha.png",
                    },
                    {
                      id: 1,
                      img: "/img/harsha-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/harsha-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="harsha"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Harsha</h4>
                    <p>Potato Supreme</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/harsha-kakkeri-28b226105/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-3" data-watch-visibility>
              <div className="team-single">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(2)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/bala.png",
                    },
                    {
                      id: 1,
                      img: "/img/bala-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/bala-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="bala"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Balavignesh</h4>
                    <p>Design Spud</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/bala-vignesh-95b456126/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(3)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/darshani.png",
                    },
                    {
                      id: 1,
                      img: "/img/darshani-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/darshani-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="darshani"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Darshani</h4>
                    <p>Design Spud</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/darshani-m/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(4)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/ishita.png",
                    },
                    {
                      id: 1,
                      img: "/img/ishita-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/ishita-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="ishita"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Ishita</h4>
                    <p>Design Spud</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/ishita-dhrangadharia/ "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single white-pseudo">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(5)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/sarun.png",
                    },
                    {
                      id: 1,
                      img: "/img/sarun-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/sarun-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="sarun"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Sarun</h4>
                    <p>Design Spud</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/sarun-k-p-71bbb2214/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single white-pseudo">
                <button
                  onMouseEnter={updateAnimhover}
                  onMouseLeave={updateAnimLeave}
                  onClick={setTeamPopActive(6)}
                  type="button"
                  className="team-img"
                >
                  {[
                    {
                      id: 0,
                      img: "/img/abhinav.png",
                    },
                    {
                      id: 1,
                      img: "/img/abhinav-2.png",
                    },
                    {
                      id: 2,
                      img: "/img/abhinav-3.png",
                    },
                  ].map(({ id, img }) => (
                    <img
                      key={id}
                      src={img}
                      className={`${id === animTeam ? " show" : ""}`}
                      alt="abhinav"
                    />
                  ))}
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Abhinav</h4>
                    <p>Design Spud</p>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/abhinav-mehrotra-182b54150/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/img/linkedin.svg" alt="linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col col-3 offset-1" data-watch-visibility>
              <div className="team-single">
                <button
                  onClick={setTeamPopActive(7)}
                  type="button"
                  className="team-img"
                >
                  <img
                    src="/img/jackie.png"
                    alt="jackie"
                    style={{ opacity: 1 }}
                  />
                </button>
                <div className="flex-row no-margin">
                  <div>
                    <h4>Jackie</h4>
                    <p>Culture Officer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-3" data-watch-visibility>
              <div className="team-single join-us">
                <div className="join-img" style={{ marginBottom: "25px" }}>
                  <img
                    src="/img/ishita.jpg"
                    alt="blank"
                    style={{ opacity: 0 }}
                  />
                  <p>Could be you!</p>
                </div>
                <div className="flex-row no-margin">
                  <div>
                    <Link to="/contact/" className="button-arrow">
                      Ping Us{" "}
                      <svg
                        width="19"
                        height="16"
                        viewBox="0 0 19 16"
                        fill="none"
                      >
                        <path
                          fill="#EA502A"
                          d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="our-culture" data-watch-visibility>
          <p style={{ color: "#EA502A" }}>NEXT UP</p>
          <h2>Check out our works</h2>
          <Link to="/#portfolio" className="button-arrow">
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
              <path
                fill="#fff"
                d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
      <div className="footer-cta">
        <div className="container">
          <div className="flex-row align-items-center" data-watch-visibility>
            <div className="col col-6">
              <h2>
                Take the next step.
                <span className="orange"> Let’s talk.</span>
              </h2>
            </div>
            <div className="col col-6">
              <a
                href="mailto:hello@thepotatostudio.co.in"
                className="flex-row footer-cta-block no-margin align-items-center"
              >
                <div>
                  <p>Write us at</p>
                  <h4>hello@thepotatostudio.co.in</h4>
                </div>
                <span className="button-arrow">
                  <svg width="19" height="16" viewBox="0 0 19 16" fill="none">
                    <path
                      fill="#fff"
                      d="M18.668 8.69a1 1 0 000-1.414L12.304.912a1 1 0 10-1.414 1.414l5.657 5.657-5.657 5.657a1 1 0 101.414 1.414l6.364-6.364zM.961 8.983h17v-2h-17v2z"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`team-popup-main ${isTeamPopActive === 0 ? "show" : ""}`}>
        <div className="team-popup-block">
          <div className="popup-head">
            <h2>Amruta</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#CFC6F4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">AKA Ambi.</div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Head potato</h3>
                <p>
                  It’s no surprise Ambi is a fan of everything potato related.
                  Much like the dreamy designs she’s famed for, dreamy Ghibli
                  movies is what she indulges in during her off hours.
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/amruta-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`team-popup-main ${isTeamPopActive === 1 ? "show" : ""}`}>
        <div className="team-popup-block orange-pop">
          <div className="popup-head">
            <h2>Harsha</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#F9CABF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">Harshi it is.</div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Supreme Potato</h3>
                <p>
                  Harshi lives by the simplest of philosophies - eat, sleep,
                  repeat. Look no further for the star member of your trivia
                  team because he'll field questions about Shaktiman to Elon
                  Musk with ease!
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/harsha-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`team-popup-main ${isTeamPopActive === 2 ? "show" : ""}`}>
        <div className="team-popup-block">
          <div className="popup-head">
            <h2>Balavignesh</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#CFC6F4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">Gonna Wiki it? ask Vikki instead.</div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Design Spud</h3>
                <p>
                  Vikki is a wizard at gaming like he is at design. Feel free to
                  quiz him about Dragon Ball Z over his favourite meal of mutton
                  biryani if you dare!
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/bala-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`team-popup-main ${isTeamPopActive === 3 ? "show" : ""}`}>
        <div className="team-popup-block orange-pop">
          <div className="popup-head">
            <h2>Darshani</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#F9CABF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">People call me “D”</div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Design Spud</h3>
                <p>
                  “D” doesn’t believe in fitting in. “Design isn’t just about
                  telling a single story to me, it’s about bringing to life a
                  multi-dimensional saga,'' she muses.
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/darshani-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`team-popup-main ${isTeamPopActive === 4 ? "show" : ""}`}>
        <div className="team-popup-block">
          <div className="popup-head">
            <h2>Ishita</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#CFC6F4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">
              Isha here, creative by heart &amp; foodie by nature{" "}
              <span rol="img">😋</span>
            </div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Design Spud</h3>
                <p>
                  Always found abundant with kindness (and a basket of baked
                  goods). If you’re wondering how she’s so good at design, it’s
                  because she believes it's a meditative process for her.
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/ishita-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`team-popup-main ${isTeamPopActive === 5 ? "show" : ""}`}>
        <div className="team-popup-block">
          <div className="popup-head">
            <h2>Sarun</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#CFC6F4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">
              Yo! Saru chetta here, yea that’s how they call me.
            </div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Design Spud</h3>
                <p>
                  Patience and dedication are central to Chetta’s way of being.
                  That being said, after running code, you can spot his quick
                  plays on the football field for a prized pot of chicken
                  biryani!
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/sarun-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`team-popup-main ${isTeamPopActive === 6 ? "show" : ""}`}>
        <div className="team-popup-block orange-pop">
          <div className="popup-head">
            <h2>Abhinav</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#F9CABF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag" style={{ maxWidth: 495 }}>
              Call me "Mehru", and if I'm not working on a new design I'm
              probably playing COD.
            </div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Design Spud</h3>
                <p>
                  Apart from great design, you can always head to Mehru for
                  binge-worthy show recommendations! He believes the way of life
                  comes through passion, growth and constant learning!
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/abhinav-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`team-popup-main ${isTeamPopActive === 7 ? "show" : ""}`}>
        <div className="team-popup-block orange-pop">
          <div className="popup-head">
            <h2>Jackie</h2>
            <button onClick={setTeamPopClose} type="button">
              <svg width="51" height="51" fill="none" viewBox="0 0 51 51">
                <path
                  stroke="#F9CABF"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M25.269 46.178c11.55 0 20.913-9.363 20.913-20.913S36.819 4.352 25.269 4.352 4.355 13.715 4.355 25.265 13.72 46.178 25.27 46.178zM31.54 18.99L18.992 31.54M18.992 18.99L31.54 31.54"
                />
              </svg>
            </button>
          </div>

          <div className="popup-body">
            <div className="name-tag">Jack without a rose.</div>

            <div className="flex-row no-margin align-items-center">
              <div className="team-pop-desc">
                <h3>Culture officer</h3>
                <p>
                  Jack is the apple of every potato’s eye. A fan of chicken (and
                  following his favourite humans around), some would argue he
                  has the studio’s most important role!
                </p>
              </div>

              <div className="team-pop-img">
                <img src="/img/jackie.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
